/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage des cartes des événements.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React from 'react';
import SectionTitle from '../sections/section-title';
import Grid from '../template/grid';
import './event-teasers.scss';
import {Link} from 'gatsby';
import {GetEvents} from './../graphql/getEvents';
import EventCard from './event-card';
import {LeanButton} from 'nedao-branding';

const EventTeasers = () => {

	const isBrowser = typeof window !== 'undefined';
	const eventsRaw = GetEvents();
	const events = eventsRaw.slice(0,4);
	const isEvent = eventsRaw.length > 0 ? true : false;
	const currentTime = new Date().getTime();

	if(isBrowser){
		return (
			<section className="teaser">
				<Grid>
					{isEvent &&
					<>
						<SectionTitle title="Evénements"/>
						<div className="section-content-events section-content">
							{events.map((event, key) => {
								let content = event.node.html
								let currentEvent = event.node.frontmatter;
								return(
									new Date(currentEvent.date).getTime() > currentTime &&
									<EventCard
										image={currentEvent.featuredImage.childImageSharp.fixed}	
										title={currentEvent.title}
										date={currentEvent.date}
										categorie={currentEvent.categorie}
										body={content}
										id={currentEvent.id}
										key={key}
									/>
		
								);
							})}
						</div>
					</>}
				</Grid>
				{isEvent &&
					<Grid>
						<div className="all-events-button" >
							<Link to="/events">
								<LeanButton text="Voir tous les événements" />
							</Link>
						</div>
						<div className="section-filet"></div>
					</Grid>
				}
			</section>
		);}
	else {
		return null;
	}
};

export default EventTeasers;

