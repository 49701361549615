/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage de la box d'inscription.

@author Victor Audetat <audetat.v@gmail.com>
@author Fabio Bonfiglio <fabio.bonfiglio@protonmail.ch>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with xhr program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React, { useState } from 'react';
import './box-inscription.scss';
import {TextInput, LeanButton} from 'nedao-branding';
import {useSpring, a} from 'react-spring';

const BoxInscription = () => {
	const [submited, setSubmited] = useState(false);
	const [replyMsg, setReplyMsg] = useState("");
	const [loading, setLoading] = useState(false);
	const [valid, setValid] = useState(false);
	const [ipLookup, setIpLookup] = useState("{\"success\": false}");
	const [address, setAddress] = useState(null);
	const [value, setValue] = useState()
	const isBrowser = typeof window !== 'undefined';

	const box = useSpring({
		from: {transform: 'translate(50vw,0)'},
		to: async (next, cancel) => {
			await setTimeout(() => {
				next({transform: 'translate(0,0)'},);
			}, 400);
		}
	});

	if(isBrowser){
		const ipReq = new XMLHttpRequest();
		ipReq.addEventListener("load", () => {
			setIpLookup(ipReq.responseText);
		});
		ipReq.open("GET", "https://json.geoiplookup.io/");
		ipReq.send();

		const validated = value => {
			if (value === null) {
				setValid(false);
				setAddress(null);
			} else {
				setAddress(value);
				setValid(true);
			}
		};
		const submitForm = event => {

			event.preventDefault();
			setLoading(true);
			const xhr = new XMLHttpRequest();
			xhr.open("POST", "submit.php", true);
			xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
			xhr.onreadystatechange = () => {

				if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
					setSubmited(true);
					if (xhr.responseText.includes("ENREGISTREMENT_OK")) {
						setReplyMsg("Inscription confirmée. 👍 Merci pour votre intérêt!");
					} else {
						const cross = "❌ ";
						if (xhr.responseText.includes("Duplicate entry")) {
							setReplyMsg(cross.concat("Cette adresse est déjà enregistrée."));
						} else if (xhr.responseText.includes("ERREUR_CONNEXION_BDD")) {
							setReplyMsg(cross.concat("Erreur d'autorisation. Veuillez contrôler la connexion sécurisée."));
						} else {
							setReplyMsg(cross.concat("Une erreur est survenue, veuillez ré-essayer."));
							console.error(xhr.responseText);
						}
						setTimeout(() => {
							setLoading(false);
							setSubmited(false);
							setReplyMsg("");
						}, 4000);
					}
				} else if (xhr.status >= 400 && !replyMsg) {
					setTimeout(() => {
						setLoading(false);
						setSubmited(false);
						setReplyMsg("");
					}, 4000);
					setReplyMsg("❌ Une erreur est survenue à l'envoi. Veuillez ré-essayer ou contrôler votre connexion.");
				}
			};
			console.log("Adresse envoyée: " + address);
			xhr.send("origine=" + ipLookup + "&adresse=" + address);
			console.group("Metadonnées: ");
			console.log(ipLookup);
			console.groupEnd();
		};

		return (
			<a.div className="box-inscription" style={box}>
				<h3>Participer à la BETA fermée</h3>
				<p>Inscrivez-vous pour être tenu·e informé·e, et pour participer aux premières phases de tests.</p>
				<form id="myForm" onSubmit={(e) => submitForm(e)} method="post">
					<TextInput
						type="email"
						slug="adresse"
						disabled={loading}
						handleChange={validated}
					/>
					<input id="locinfo" name="origine" type="hidden" disabled={loading} value={ipLookup}/>
					<p hidden={!submited}>{replyMsg}</p>
					<button type="submit" disabled={loading || !valid} hidden={submited}>
						<LeanButton
							isImportant={true}
							text={'S\'inscrire'}
						/>
					</button>
				</form>
			</a.div>
		);}
	else return null;
};

export default BoxInscription;
