import {useStaticQuery, graphql} from 'gatsby';

export const GetSectionText = () =>  {
	const content = useStaticQuery(graphql`
	query SectionTextQuery {
		allMarkdownRemark(filter: {frontmatter: {type: {eq: "section-text"}}}){
			edges {
				node {
					html
					frontmatter {
                        title
						content
					}
				}
			}
		}
	}`);
	return content.allMarkdownRemark.edges;
};