/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage de la section partenaire.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React from 'react';
import Grid from '../template/grid';
import SectionTitle from './section-title';
import './partenaires.scss';
import { GetPartenaires } from '../graphql/getPartenaires';
import Img from 'gatsby-image';

const Partenaires = () => {
	const partenaires = GetPartenaires();
	return (
		<section>
			<Grid>
				<SectionTitle title="Partenaires"/>
				<section className="section-content section-content-partenaires">
					{partenaires.map((partenaire, key) => (
						<a key={key} target="_blank" href={partenaire.node.frontmatter.link} className={`${partenaire.node.frontmatter.name}-link`} rel="noreferrer">
							<Img className={`partenaire ${partenaire.node.frontmatter.name}`} fixed={partenaire.node.frontmatter.featuredImage.childImageSharp.fixed}/>
						</a>
					))}
				</section>
			</Grid>
		</section>
	);
};

export default Partenaires;