import React, {useState}  from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import dateformat from 'dateformat';
import './article-card.scss';
import {useSpring, a} from 'react-spring';


const ArticleCard = (props) => {

	const [hover, setHover] = useState(false);

	const hoverCard = useSpring({
		from: {transform: 'scale(100%)'},
		to: {transform: hover ? 'scale(110%)' : 'scale(100%)'},
		config: {mass: 0.1, tenstion: 50, friction: 5,}
	});

	const handleHover = (attr) => {
		setHover(attr);
	};

	const date = new Date(props.date).getTime();
	const formatDate = 	dateformat(date, 'dd.mm.yyyy');

	console.log('props articleCards', props);
	
	return (
		<Link role="button" onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)} to={`/news/${props.id}`}				
			data-sal="slide-up"
			data-sal-delay={300}
			data-sal-easing="ease">
			<div className="article-card">
				<Img fixed={props.image} />
				<a.div className="card-content" style={hoverCard}>
					<p className="card-title">{props.title}</p>
					<div className="card-info" dangerouslySetInnerHTML={{__html: `<div>${props.chapo.split(' ').slice(0, 20).join(' ') + '...'}</div>`}}></div>
					<p className="card-author">Ecrit par <span className="card-type">{props.author}</span></p>
					<p className="card-date">Publié le {formatDate}</p>
					<p className="card-more" > <span>→</span> Lire la suite</p>
				</a.div>
			</div>
		</Link>
	);
};

export default ArticleCard;