/******************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Page d'accueil.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import * as React from 'react';
import ArticleTeasers from '../components/articles/articles-teaser';
import EventTeasers from '../components/events/events-teasers';
import Headline from '../components/sections/headline-section';
import Partenaires from '../components/sections/partenaires-section';
import Texte from '../components/sections/text-section';
import Layout from '../components/template/layout';

const IndexPage = () => {
	return (
		<Layout 
			title="Accueil"
			lang="fr"
			description="Page d'accueil du projet NEDAO"
			social={
				{
					type: 'website',
				}
			}
		>
			<Headline  
			/>
			<Texte />
			<EventTeasers/>
			<ArticleTeasers/>
			<Partenaires />
		</Layout>
	);
};

export default IndexPage;
