import {useStaticQuery, graphql} from 'gatsby';

export const GetEvents = () =>  {
	const content = useStaticQuery(graphql`
	query EventQuery {
		allMarkdownRemark(
			filter: {
				frontmatter: {
					type: {
						eq: "event"
					}
				}
			}
			sort: {
				fields: [frontmatter___date]
				order: ASC
			}
			)
			{
			edges {
				node {
					frontmatter {
						categorie
						date
						id
						chapo
						title
						type
						altTextImage
						marginalContent
						paragraphes
						featuredImage {
							childImageSharp {
								fixed(width: 1200){
									src
								}
							}
						}
					}
					html
				}
			}
		}
	}`);

	return content.allMarkdownRemark.edges;
};




