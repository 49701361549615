import React, {useState, useEffect} from 'react';
import { a ,useSpring} from 'react-spring';

const Rocket = () => {

	const [pos, setPos] = useState([0,0]);

	useEffect(() => {
		setInterval(() => {
			setPos([getRandomInt(-8, 8), getRandomInt(-8, 8)]);
		}, 500);
	}, []);


	const getRandomInt = (min, max) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min)) + min;
	};

	
	const zeroG = useSpring({
		from: {
			transform:  'translate(0px,0px) rotate(-10deg)', 
			position: 'relative',
		},
		to: {
			transform: `translate(${pos[0]},${pos[1]}) rotate(33deg)`, 
			position: 'relative',
		},
		config: {friction: 0, mass: 50, tension: 0, velocity: 1},
	});

	return (
		<a.div style={zeroG}>
			<svg xmlns="http://www.w3.org/2000/svg" style={zeroG} width="703" height="787" viewBox="0 0 703 787">
				<defs>
					<clipPath id="clip-Plan_de_travail_1">
						<rect width="703" height="787" />
					</clipPath>
				</defs>
				<g id="Plan_de_travail_1" data-name="Plan de travail – 1" clipPath="url(#clip-Plan_de_travail_1)">
					<path id="Union_14" data-name="Union 14" d="M234.611,591.867A31.186,31.186,0,0,1,213.9,558.009h0q.158-1.069.395-2.138l0-.01.024-.108,0-.017.025-.108.01-.042.014-.062.013-.055.01-.044.028-.118.006-.027a31.335,31.335,0,0,1,3.131-7.925l.011-.019.118-.208.033-.058.026-.045.059-.1L223.7,536.77h-38.27a15.077,15.077,0,0,1-2.792-.259l-.665,24.852a30.883,30.883,0,0,1-30.883,30.883l-120.2,18.046A30.885,30.885,0,0,1,0,579.407V525.822c0-4.776,3.911-14,7.261-19.47L163.971,265.98q4.816-20.344,10.416-43.362C225.938,10.788,299.027-.208,314.839,0a18.59,18.59,0,0,1,2.812.179c.206-.028.5-.062.879-.094C319,.044,319.619.007,320.373,0c15.779-.218,88.9,10.715,140.464,222.615,5.159,21.2,9.8,40.643,13.971,58.464L621.671,506.353c3.35,5.472,7.261,14.693,7.261,19.47v53.585a30.885,30.885,0,0,1-30.884,30.884l-120.2-18.046a30.883,30.883,0,0,1-30.884-30.883l-.909-33.949a15,15,0,0,1-13.9,9.357H390.133l5.892,10.155.059.1.026.045.032.056.071.124.006.011.043.076.008.015a31.323,31.323,0,0,1,3.112,7.847v0l.018.074.008.035.02.082.018.078.01.044.018.076.008.033.026.114v0l.027.119,0,.007q.237,1.069.4,2.139h0a31.262,31.262,0,0,1-14.946,31.213c-15.613,37.429-22.867,129.164-72.938,129.164C266.711,718.386,253.47,630.789,234.611,591.867Z" transform="translate(38.225 32.991)" fill="none" stroke="#707070" strokeWidth="62" />
					<path id="Tracé_111" data-name="Tracé 111" d="M-9266.643-716.85c21.758,32.276,33.188,135.227,82.339,135.227,53.042,0,58.036-102.951,75.817-135.227" transform="translate(9534.578 1333)" fill="#5aa5df" />
					<path id="Tracé_112" data-name="Tracé 112" d="M-9266.643-716.85c14.583,21.633,23.722,85.026,56.025,84.165,35.552,0,38.063-62.531,49.98-84.165" transform="translate(9560.653 1315.746)" fill="#efd005" />
					<path id="Polygone_28" data-name="Polygone 28" d="M89.312,46.21a31,31,0,0,1,53.626,0l62.3,107.362a31,31,0,0,1-26.813,46.558H53.828a31,31,0,0,1-26.813-46.558Z" transform="translate(229.016 426.344)" fill="#ec5156" />
					<path id="Intersection_11" data-name="Intersection 11" d="M23.107,164.656a31.081,31.081,0,0,1,3.908-11.084l16.2-27.915H189.036l16.2,27.915a31.081,31.081,0,0,1,3.908,11.084Z" transform="translate(229.016 426.344)" fill="#e41018" />
					<path id="Tracé_113" data-name="Tracé 113" d="M7.261,299.793,196.267,9.883,225.062,14.2,186.4,189.52,181.971,354.8a30.884,30.884,0,0,1-30.884,30.884l-120.2,18.045A30.884,30.884,0,0,1,0,372.848V319.263C0,314.487,3.912,305.266,7.261,299.793Z" transform="translate(38.225 239.55)" fill="#059f79" />
					<path id="Tracé_114" data-name="Tracé 114" d="M217.8,299.793,28.795,9.883,0,14.2,38.665,189.52,43.09,354.8a30.884,30.884,0,0,0,30.884,30.884l120.2,18.045a30.884,30.884,0,0,0,30.884-30.884V319.263C225.062,314.487,221.15,305.266,217.8,299.793Z" transform="translate(442.096 239.55)" fill="#059f79" />
					<path id="Intersection_12" data-name="Intersection 12" d="M58.223,240.363S8.387,254.747.872,223.185,32.575,45.25,32.575,45.25l37.9-57.009,71.4,228.007Z" transform="translate(142.197 295.927)" fill="#058465" />
					<path id="Intersection_13" data-name="Intersection 13" d="M56.308,1.271S6.472-13.114-1.043,18.448s31.7,177.935,31.7,177.935l37.9,57.009,71.4-228.007Z" transform="translate(566.27 544.56) rotate(180)" fill="#058465" />
					<path id="Tracé_117" data-name="Tracé 117" d="M-6206.457,203.023s-26.675,2.122-26.675-21.153V151.728l25.414-1.526,1.261,40.206Z" transform="translate(6427.065 372)" fill="#058465" />
					<path id="Tracé_118" data-name="Tracé 118" d="M-6233.131,203.023s26.675,2.122,26.675-21.153V151.728l-25.414-1.526-1.261,40.206Z" transform="translate(6717.905 372)" fill="#058465" />
					<rect id="Rectangle_67" data-name="Rectangle 67" width="276.723" height="75.357" rx="15" transform="translate(208.652 494.403)" fill="#f2f2f2" />
					<rect id="Rectangle_68" data-name="Rectangle 68" width="276.723" height="69.709" transform="translate(208.652 462.373)" fill="#cfcfcf" />
					<path id="Union_15" data-name="Union 15" d="M339.155,487.66H43.628c-13.112,0-31.565.32-38.747-5.494S-9.927,461.337,48.166,222.618C104.2-7.621,185.674-.6,191.391.187c5.718-.789,87.194-7.809,143.225,222.431,58.094,238.719,50.47,253.733,43.287,259.548-6.47,5.237-22.086,5.5-34.7,5.5C341.809,487.663,340.456,487.66,339.155,487.66Z" transform="translate(164.445 32.991)" fill="#e6e6e6" />
					<path id="Union_16" data-name="Union 16" d="M295.372,487.66H38c-11.419,0-27.49.32-33.745-5.494s-12.9-20.829,37.7-259.548C90.746-7.621,161.7-.6,166.684.187c4.98-.789,75.938-7.809,124.736,222.431,50.595,238.719,43.955,253.733,37.7,259.548-5.634,5.237-19.234,5.5-30.224,5.5C297.684,487.663,296.506,487.66,295.372,487.66Z" transform="translate(189.242 32.994)" fill="#fff" />
					<path id="Polygone_30" data-name="Polygone 30" d="M143.646,0a17,17,0,0,1,14.732,8.517L199.747,80.36a17,17,0,0,1,0,16.966L158.378,169.17a17,17,0,0,1-14.732,8.517H60.986a17,17,0,0,1-14.732-8.517L4.885,97.327a17,17,0,0,1,0-16.966L46.254,8.517A17,17,0,0,1,60.986,0Z" transform="translate(309.452 150.59) rotate(30)" fill="#b7b7b7" />
					<path id="Polygone_31" data-name="Polygone 31" d="M143.646,0a17,17,0,0,1,14.732,8.517L199.747,80.36a17,17,0,0,1,0,16.966L158.378,169.17a17,17,0,0,1-14.732,8.517H60.986a17,17,0,0,1-14.732-8.517L4.885,97.327a17,17,0,0,1,0-16.966L46.254,8.517A17,17,0,0,1,60.986,0Z" transform="translate(309.452 143.086) rotate(30)" fill="#d0d0d0" />
					<circle id="Ellipse_61" data-name="Ellipse 61" cx="4" cy="4" r="4" transform="translate(350 355)" fill="#9b9b9b" />
					<circle id="Ellipse_62" data-name="Ellipse 62" cx="4" cy="4" r="4" transform="translate(350 180)" fill="#9b9b9b" />
					<circle id="Ellipse_63" data-name="Ellipse 63" cx="4" cy="4" r="4" transform="translate(283.688 226.286) rotate(120)" fill="#9b9b9b" />
					<circle id="Ellipse_64" data-name="Ellipse 64" cx="4" cy="4" r="4" transform="translate(435.241 313.786) rotate(120)" fill="#9b9b9b" />
					<circle id="Ellipse_65" data-name="Ellipse 65" cx="4" cy="4" r="4" transform="translate(428.313 233.214) rotate(-120)" fill="#9b9b9b" />
					<circle id="Ellipse_66" data-name="Ellipse 66" cx="4" cy="4" r="4" transform="translate(276.759 320.714) rotate(-120)" fill="#9b9b9b" />
					<circle id="Ellipse_67" data-name="Ellipse 67" cx="4" cy="4" r="4" transform="translate(314.759 345.714) rotate(-120)" fill="#9b9b9b" />
					<circle id="Ellipse_68" data-name="Ellipse 68" cx="4" cy="4" r="4" transform="translate(392.759 208.714) rotate(-120)" fill="#9b9b9b" />
					<circle id="Ellipse_69" data-name="Ellipse 69" cx="4" cy="4" r="4" transform="translate(270.936 273.689) rotate(-60)" fill="#9b9b9b" />
					<circle id="Ellipse_70" data-name="Ellipse 70" cx="4" cy="4" r="4" transform="translate(428.582 272.739) rotate(-60)" fill="#9b9b9b" />
					<circle id="Ellipse_71" data-name="Ellipse 71" cx="4" cy="4" r="4" transform="translate(311.4 199.725)" fill="#9b9b9b" />
					<circle id="Ellipse_72" data-name="Ellipse 72" cx="4" cy="4" r="4" transform="translate(391.045 335.775)" fill="#9b9b9b" />
					<path id="Polygone_32" data-name="Polygone 32" d="M108.67,0A17,17,0,0,1,123.4,8.517l29.71,51.6a17,17,0,0,1,0,16.966l-29.71,51.6a17,17,0,0,1-14.732,8.517H49.327A17,17,0,0,1,34.6,128.676L4.885,77.08a17,17,0,0,1,0-16.966L34.6,8.517A17,17,0,0,1,49.327,0Z" transform="translate(319.521 172.279) rotate(30)" fill="#fff" opacity="0.56" />
					<g id="source_images_NEDAO_logo" transform="translate(294.011 203.787)">
						<path id="Tracé_1" data-name="Tracé 1" d="M46.627,0V59.7L19.508,26.672,4.617,35.481a12.554,12.554,0,0,0-4.6,9.711L0,110.94l18.846,10.882.014-59.606,27.081,33L60.856,86.44a12.56,12.56,0,0,0,4.593-9.711l.017-65.847Z" transform="translate(26.957 6.074)" fill="#fff" />
						<path id="Tracé_2" data-name="Tracé 2" d="M67.282,11.936,46.61,0l-.017,65.847A12.564,12.564,0,0,1,42,75.558L27.081,84.336,0,51.344V110.95l7.607,4.39a12.567,12.567,0,0,0,12.567,0l47.108-27.2a12.564,12.564,0,0,0,6.285-10.882V22.828A12.567,12.567,0,0,0,67.282,11.936Z" transform="translate(45.817 16.946)" fill="#ed1c24" />
						<path id="Tracé_3" data-name="Tracé 3" d="M73.571,65.777V6.074l-7.607-4.39a12.567,12.567,0,0,0-12.567,0L6.285,28.881A12.567,12.567,0,0,0,0,39.763V94.2a12.564,12.564,0,0,0,6.285,10.892l20.675,11.936.021-65.748a12.574,12.574,0,0,1,4.6-9.711l14.891-8.808Z" transform="translate(0 0)" fill="#00a77e" />
						<path id="Tracé_4" data-name="Tracé 4" d="M12.234,0H8.156V8.156H0v4.078H8.156v8.156h4.078V12.234h8.156V8.156H12.234Z" transform="translate(95.715 32.414)" fill="#fff" />
					</g>
				</g>
			</svg>
		</a.div>

	);
};
export default Rocket;