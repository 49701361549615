/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage d'une section composé d'un titre et d'un texte.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React from 'react';
import { GetSectionText } from '../graphql/getSectionText';
import Grid from '../template/grid';
import SectionTitle from './section-title';
import './text-section.scss';

const Texte = () => {
	const content = GetSectionText();
	return (
		content.map((section, key) => (
			<section key={key} 			
				data-sal="slide-up"
				data-sal-delay={200}
				data-sal-easing="ease">
				<Grid className="section-texte">
					<SectionTitle title={section.node.frontmatter.title}/>
					<div className="section-content section-filet">
						<p>{section.node.frontmatter.content}</p>
					</div>
				</Grid>
			</section>
		))
	);
};

export default Texte;