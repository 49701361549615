/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage de la section d'intro.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React from 'react';
import BoxInscription from '../box-inscription/box-inscription';
import Grid from './../template/grid';
import './headlines.scss';
import Rocket from './../../images/rocket';
import {useSpring, a} from 'react-spring';




const Headline = () => {
	const liftOff = useSpring({
		from: {transform:  'translate(-200px,500px)',},
		to: {
			transform: 'translate(0px,0px)'
		},
		config: {friction: 50, mass: 10, tension: 200, velocity: 1},
	});

	return (
		<section className="headline-section">
			<Grid> 
				<a.div className="rocket" style={liftOff}>
					<Rocket />
				</a.div>
				<div 
					className="introduction"
					data-sal="slide-up"
					data-sal-delay="150"
					data-sal-easing="ease"
				>				
					<h2>Envie d'un avant-goût d'avenir ?</h2>
					<h3>La plateforme participative de demain, pour les Neuchâtelois, par les Neuchâtelois... </h3>
					<p>Téléchargez le <a href="/NEDAO_Dossier_Presentation.pdf"> <span role="img" aria-label="emoji dossier">📗</span> dossier de présentation du projet</a> pour plus de détails.</p>
				</div>
				<BoxInscription />
			</Grid>
		</section>
	);
};

export default Headline;
