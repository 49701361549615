import {useStaticQuery, graphql} from 'gatsby';

export const GetPartenaires = () =>  {
	const content = useStaticQuery(graphql`
	query PartenairesQuery {
		allMarkdownRemark(
			filter: {
				frontmatter: {
					type: {
						eq: "partenaire"
					}
				}
			}
			sort: {
				fields: [frontmatter___order]
				order: ASC
			}
			){
			edges {
				node {
					frontmatter {
						name
						link
						featuredImage {
							childImageSharp {
								fixed(width: 800){
									src
								}
							}
						}
					}
				}
			}
		}
	}`);

	return content.allMarkdownRemark.edges;
};




