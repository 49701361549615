/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage des cartes des événements.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React from 'react';
import SectionTitle from '../sections/section-title';
import Grid from '../template/grid';
import './article-teasers.scss';
import {Link} from 'gatsby';
import {GetNews} from './../graphql/getNews';
import ArticleCard from './article-card';
import {LeanButton} from 'nedao-branding';

const ArticleTeasers = () => {

	const ArticlesRaw = GetNews();
	const Articles = ArticlesRaw.slice(0,4);
	const isBrowser = typeof window !== 'undefined';
	const isArticle = Articles.length > 0 ? true : false;
	const currentTime = new Date().getTime();

	if(isBrowser){
		return (
			<section className="teaser">
				<Grid>
					{isArticle &&
					<>
						<SectionTitle title="Articles"/>
						<div className="section-content-news section-content">
							{Articles.map((Article, key) => {
								let content = Article.node.html;
								const currentArticle = Article.node.frontmatter;
								return(
									new Date(currentArticle.date).getTime() < currentTime &&
									<ArticleCard
										image={currentArticle.featuredImage.childImageSharp.fixed}	
										key={key}
										title={currentArticle.title ? currentArticle.title : ''}
										date={currentArticle.date}
										author={currentArticle.author}
										body={content}
										chapo={currentArticle.chapo ? currentArticle.chapo : ''}
										id={currentArticle.id}
									/>
								);
							})}
						</div>
					</>}
				</Grid>
				{isArticle &&
					<Grid>
						<SectionTitle/>
						<div className="all-news-button" >
							<Link to="/news">
								<LeanButton text="Voir tous les articles" />
							</Link>
						</div>
						<div className="section-filet"></div>
					</Grid>
				}
			</section>
	
		);}
	else return null;
};

export default ArticleTeasers;

