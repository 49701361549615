import {useStaticQuery, graphql} from 'gatsby';

export const GetNews = () =>  {
	const content = useStaticQuery(graphql`
	query NewsQuery {
		allMarkdownRemark(
			filter: {frontmatter: {type: {eq: "article"}}}
			sort: {fields: frontmatter___date, order: DESC}
			){
			edges {
				node {
					frontmatter {
						paragraphes
						type
                        title
                        date
                        author
                        id
						chapo
						altTextImage
						marginalContent
						featuredImage {
							childImageSharp {
								fixed(width: 1200){
									src
								}
							}
						}
					}
					html
				}
			}
		}
	}`);

	return content.allMarkdownRemark.edges;
};