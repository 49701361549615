import React, {useState} from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import dateformat from 'dateformat';
import './event-card.scss';
import {useSpring, a} from 'react-spring';


const EventCard = (props) => {

	const [hover, setHover] = useState(false);

	const hoverCard = useSpring({
		from: {transform: 'scale(100%)'},
		to: {transform: hover ? 'scale(110%)' : 'scale(100%)'},
		config: {mass: 0.1, tenstion: 50, friction: 5,}
	});

	const handleHover = (attr) => {
		setHover(attr);
	};

	const eventDate = new Date(props.date).getTime();
	const now = Date.now();
	const formatDate = 	dateformat(props.date, 'dd.mm.yyyy');

	return (
		eventDate > now &&
			<Link  onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)} to={`/event/${props.id}`}
				data-sal="slide-up"
				data-sal-delay={200}
				data-sal-easing="ease">
				<div className="event-card">
					<Img fixed={props.image} />
					<a.div  className="card-content" style={hoverCard}>
						<div className="card-header">
							<div className="card-date-categorie">
								<p className="card-date">{formatDate}</p>
								<p className="card-type">{props.categorie}</p>
							</div>
						</div>
						<p className="card-title">{props.title}</p>
						<div className="card-info" dangerouslySetInnerHTML={{__html: `<div>${ props.body ? props.body.split(' ').slice(0, 20).join(' ') + '...' : ''}</div>`}}></div>
						<p className="card-more" > <span>→</span> Plus de détails</p>
					</a.div>
				</div>
			</Link>
	);
};

export default EventCard;